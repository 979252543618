// Imports
// ------------
import styled, { css, keyframes } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------------
export const ToggleJacket = styled.div`
    transition: all 1.2s ${props => props.theme.bezzy};
    transform: translateY(${props => props.isVisible ? `0` : `-24rem`});

    position: fixed;
    z-index: 12;

    top: 2.4rem;
    right: 1.2rem;

    width: 6rem;
    height: 5.28rem;
`;

const hamburger = keyframes`
    from { transform: rotate(0deg);  }
    to {  transform: rotate(270deg);  }
`;

const hamburgerRev = keyframes`
    to { transform: rotate(0deg);  }
    from {  transform: rotate(270deg);  }
`;

const bunTop = keyframes`
    from { transform: translateY(-3px) rotate(0deg); }
    to {  transform: translateY(0px) rotate(-45deg); }
`;

const bunTopRev = keyframes`
    to { transform: translateY(-3px) rotate(0deg); }
    from {  transform: translateY(0px) rotate(-45deg); }
`;

const bunBot = keyframes`
    from {  transform: translateY(3px) rotate(0deg); }
    to { transform: translateY(0px) rotate(-135deg); }
`;

const bunBotRev = keyframes`
    to {  transform: translateY(3px) rotate(0deg); }
    from { transform: translateY(0px) rotate(-135deg); }
`;


export const Jacket = styled.button`
    position: fixed;
    z-index: 12;

    width: 6rem;
    height: 5.28rem;
    min-width: auto;
    padding: 0;

    border: none;
    background: transparent;
    transition: all 0.75s ${p => p.theme.bezzy};

    &:hover {
        ${breakup.large`
            opacity: 0.2;
            cursor: pointer;

            span:first-child {
                transform: translateY(-3px);
            }

            span:last-child {
                transform: translateY(3px);
            }
        `}
    }

    &:active,
    &:focus {
        opacity: 1;
    }

    ${p => p.isOpen ? css`
        animation: 1s ${hamburger} ${p.theme.bezzy} forwards;

        span:first-child {
            animation: 1s ${bunTop} ${p.theme.bezzy} forwards;
        }

        span:last-child {
            animation: 1s ${bunBot} ${p.theme.bezzy} forwards;
        }
    ` : css`
        animation: 1s ${hamburgerRev} ${p.theme.bezzy} forwards;

        span:first-child {
            animation: 1s ${bunTopRev} ${p.theme.bezzy} forwards;
        }

        span:last-child {
            animation: 1s ${bunBotRev} ${p.theme.bezzy} forwards;
        }
    `}

    span {
        position: absolute;
        top: 50%;
        left: 1.2rem;

        width: 3.6rem;
        height: 2px;

        background: ${p => p.isOpen ? p.theme.white : p.theme.white};
        transition: all 0.8s ${p => p.theme.bezzy};

        &:first-child {
            transform: translateY(-3px);
        }

        &:last-child {
            transform: translateY(3px);
        }
    }
`;
