// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Icon = ({ type, className, onClick }) => {
	if (type === 'brand')
		return (
			<Jacket
				height='60px'
				width='60px'
				viewBox='0 0 60 60'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d="M30 0C24.75 0 19.875 1.35 15.6 3.675L18.75 5.325C22.2 3.75 25.95 2.85 30 2.85C45 2.85 57.15 15 57.15 30C57.15 45 45 57.15 30 57.15V60C46.575 60 60 46.575 60 30C60 13.5 46.575 0 30 0Z" fill="#F4C00C" />
				<path d="M54.075 26.9999L54 26.2499L42.3 20.2499L36.15 14.1L24.45 17.0249L13.95 11.775C13.575 12.15 13.125 12.525 12.75 12.9V13.5C12.75 16.05 13.5 18.975 14.775 21.225C15.975 23.3249 17.55 24.75 19.35 25.35L8.625 46.7249C5.175 42.3749 3.225 37.0499 2.925 31.425H12.9V28.5749H2.925C3.375 20.6249 7.2 13.5 13.05 8.84995L24.15 14.4L37.5 11.1L36.75 8.32495L31.5 9.59995L27.75 5.84995C26.55 5.92495 25.425 6.14995 24.3 6.44995L28.275 10.425L24.45 11.4L7.05 2.69995C6.3 3.37495 5.55 4.04995 4.8 4.79995L10.275 7.49995C3.975 12.975 0 21.0749 0 29.9999C0 37.4999 2.775 44.6999 7.875 50.2499L9.3 51.7499L23.775 22.8H21.45C18.525 22.8 16.575 19.2749 15.9 15.9L24.15 20.0249L35.25 17.3249L40.575 22.6499L51.375 28.125C51.45 28.725 51.45 29.4 51.45 30.075C51.45 30.6 51.45 31.1249 51.375 31.6499L29.1 34.4999L22.725 47.2499H25.95L30.975 37.1249L54 34.125L54.15 32.9999C54.3 32.0249 54.3 30.9749 54.3 29.9999C54.3 29.0249 54.225 27.9749 54.075 26.9999Z" fill="white" />
				<path d="M8.625 46.725C5.175 42.375 3.225 37.05 2.925 31.425H12.9V28.575H2.925C3.375 20.625 7.2 13.5 13.05 8.85005L10.275 7.42505C3.975 12.975 0 21.075 0 30C0 36 1.8 41.775 5.1 46.725H8.625Z" fill="#F4C00C" />
			</Jacket>
		);
	
	if (type === '12')
		return (
			<Jacket
				width="129"
				height="93"
				viewBox="0 0 129 93"
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path fillRule="evenodd" clipRule="evenodd" d="M0 0H23.2955V23.25H0V0ZM34.9431 0H58.2386V23.25H34.9431V0ZM23.2955 34.875H0V58.125H23.2955V34.875ZM34.9432 58.125H58.2387V34.875C45.3777 34.875 34.9432 45.2891 34.9432 58.125ZM23.2955 69.75H0V93H23.2955V69.75ZM34.9432 69.75H58.2387V93H34.9432V69.75ZM93.1818 0H69.8864V23.25H93.1818V0ZM104.83 23.25H128.125C128.125 10.4141 117.691 0 104.83 0V23.25ZM93.1818 34.875H69.8864V58.125H93.1818V34.875ZM104.83 34.875V58.125C117.691 58.125 128.125 47.7109 128.125 34.875H104.83ZM93.1818 69.75H69.8864V93H93.1818V69.75ZM104.83 69.75H128.125V93H104.83V69.75Z" fill="white" />
			</Jacket>
		);


	if (type === 'facebook')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d='M24,12.072A12,12,0,1,0,10.125,23.926V15.541H7.078V12.072h3.047V9.428c0-3.007,1.792-4.669,4.532-4.669a18.611,18.611,0,0,1,2.687.234V7.947H15.83a1.734,1.734,0,0,0-1.947,1.49,1.71,1.71,0,0,0-.008.385v2.25H17.2l-.532,3.469h-2.8v8.385A12,12,0,0,0,24,12.072Z' />
			</Jacket>
		);

	if (type === 'codepen')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d='M24,8.2C24,8.2,24,8.2,24,8.2c0-0.1,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1 c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l-11-7.3 c-0.3-0.2-0.8-0.2-1.1,0l-11,7.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1 c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1v7.3 c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1 c0,0,0,0,0,0c0,0,0,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0l11,7.3c0.2,0.1,0.4,0.2,0.6,0.2 c0.2,0,0.4-0.1,0.6-0.2l11-7.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1 c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1V8.3 C24,8.3,24,8.3,24,8.2z M13,3l8.1,5.4l-3.6,2.4l-4.5-3V3z M11,3v4.8l-4.5,3L2.9,8.3L11,3z M2.1,10.3L4.6,12l-2.6,1.7V10.3z M11,21 l-8.1-5.4l3.6-2.4l4.5,3V21z M12,14.4L8.4,12L12,9.6l3.6,2.4L12,14.4z M13,21v-4.8l4.5-3l3.6,2.4L13,21z M21.9,13.7L19.4,12l2.6-1.7 V13.7z' />
			</Jacket>
		);

	if (type === 'dribbble')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M22.1,13.6c-0.4-0.1-3.2-1-6.4-0.4c1.3,3.7,1.9,6.7,2,7.3C20,19,21.7,16.5,22.1,13.6z M16,21.5c-0.2-0.9-0.7-4-2.2-7.8 c0,0,0,0-0.1,0c-5.8,2-7.9,6-8,6.4c1.7,1.4,3.9,2.2,6.3,2.2C13.4,22.3,14.8,22,16,21.5z M4.4,18.9c0.2-0.4,3-5.1,8.3-6.8 c0.1,0,0.3-0.1,0.4-0.1c-0.3-0.6-0.5-1.2-0.8-1.7c-5.1,1.5-10.1,1.5-10.5,1.5c0,0.1,0,0.2,0,0.3C1.8,14.6,2.7,17.1,4.4,18.9z M2,9.9 c0.5,0,4.7,0,9.5-1.2c-1.7-3-3.5-5.6-3.8-5.9C4.8,4.1,2.6,6.7,2,9.9z M9.6,2.1c0.3,0.4,2.1,2.9,3.8,6c3.6-1.4,5.2-3.4,5.4-3.7 C17,2.7,14.6,1.8,12,1.8C11.2,1.8,10.4,1.9,9.6,2.1z M19.9,5.5c-0.2,0.3-1.9,2.5-5.7,4c0.2,0.5,0.5,1,0.7,1.5 c0.1,0.2,0.1,0.4,0.2,0.5c3.4-0.4,6.8,0.3,7.1,0.3C22.2,9.5,21.4,7.3,19.9,5.5z'
				/>
			</Jacket>
		);

	if (type === 'linkedin')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d='M23,0H1C0.4,0,0,0.4,0,1v22c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V1C24,0.4,23.6,0,23,0z M7.1,20.5H3.6V9h3.6 V20.5z M5.3,7.4c-1.1,0-2.1-0.9-2.1-2.1c0-1.1,0.9-2.1,2.1-2.1c1.1,0,2.1,0.9,2.1,2.1C7.4,6.5,6.5,7.4,5.3,7.4z M20.5,20.5h-3.6 v-5.6c0-1.3,0-3-1.8-3c-1.9,0-2.1,1.4-2.1,2.9v5.7H9.4V9h3.4v1.6h0c0.5-0.9,1.6-1.8,3.4-1.8c3.6,0,4.3,2.4,4.3,5.5V20.5z' />
			</Jacket>
		);

	if (type === 'flickr')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d="M23,0H1C0.4,0,0,0.4,0,1v22c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V1C24,0.4,23.6,0,23,0z M7,16 c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C11,14.2,9.2,16,7,16z M17,16c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4 C21,14.2,19.2,16,17,16z" />
			</Jacket>
		);

	if (type === 'left')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d='M1.293,11.293L9,3.586L10.414,5l-6,6H22c0.553,0,1,0.448,1,1s-0.447,1-1,1H4.414l6,6L9,20.414l-7.707-7.707 C0.902,12.316,0.902,11.684,1.293,11.293z' />
			</Jacket>
		);

	if (type === 'up')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d='M12.707,1.293L20.414,9L19,10.414l-6-6V22c0,0.553-0.448,1-1,1s-1-0.447-1-1V4.414l-6,6L3.586,9l7.707-7.707 C11.684,0.902,12.316,0.902,12.707,1.293z' />
			</Jacket>
		);

	if (type === 'down')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d="M12.707,22.707L20.414,15L19,13.586l-6,6V2c0-0.553-0.448-1-1-1s-1,0.447-1,1v17.586l-6-6L3.586,15 l7.707,7.707C11.684,23.098,12.316,23.098,12.707,22.707z" />
			</Jacket>
		);

	if (type === 'right')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d="M22.707,11.293L15,3.586L13.586,5l6,6H2c-0.553,0-1,0.448-1,1s0.447,1,1,1h17.586l-6,6L15,20.414 l7.707-7.707C23.098,12.316,23.098,11.684,22.707,11.293z"></path>
			</Jacket>
		);

	if (type === 'quote')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d="M9 23H1C0.44775 23 0 22.5527 0 22V12C0 6.95605 1.89014 3.61719 5.61768 2.07568C6.12793 1.86572 6.7124 2.10742 6.92432 2.61768C7.13526 3.12842 6.89258 3.71289 6.38233 3.92432C3.67383 5.04395 2.24365 7.36328 2.02881 11H9C9.55225 11 10 11.4478 10 12V22C10 22.5527 9.55225 23 9 23Z" />
				<path d="M23 23H15C14.4477 23 14 22.5527 14 22V12C14 6.95605 15.8901 3.61719 19.6177 2.07568C20.1265 1.86572 20.7124 2.10742 20.9243 2.61767C21.1353 3.12841 20.8926 3.71288 20.3823 3.92431C17.6738 5.04395 16.2437 7.36328 16.0288 11H23C23.5523 11 24 11.4478 24 12V22C24 22.5527 23.5523 23 23 23Z" />
			</Jacket>
		);

	if (type === 'star')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d="M15.1441 5.43906L10.8271 4.81106L8.90008 0.900057C8.80851 0.742522 8.67719 0.611782 8.51925 0.520906C8.36132 0.430031 8.18229 0.382202 8.00008 0.382202C7.81786 0.382202 7.63884 0.430031 7.4809 0.520906C7.32297 0.611782 7.19165 0.742522 7.10008 0.900057L5.17308 4.81206L0.856079 5.43906C0.671214 5.46557 0.497468 5.54332 0.354521 5.6635C0.211573 5.78369 0.105137 5.9415 0.0472671 6.11906C-0.0106025 6.29662 -0.0175924 6.48685 0.0270889 6.66818C0.0717703 6.84951 0.166337 7.01471 0.300079 7.14506L3.42308 10.1901L2.68608 14.4901C2.65457 14.674 2.67514 14.863 2.74546 15.0358C2.81578 15.2087 2.93305 15.3584 3.08401 15.468C3.23497 15.5777 3.41359 15.6429 3.59969 15.6563C3.78579 15.6698 3.97193 15.6309 4.13708 15.5441L8.00008 13.5131L11.8611 15.5421C12.0262 15.6289 12.2124 15.6678 12.3985 15.6543C12.5846 15.6409 12.7632 15.5757 12.9142 15.466C13.0651 15.3564 13.1824 15.2067 13.2527 15.0338C13.323 14.861 13.3436 14.672 13.3121 14.4881L12.5751 10.1881L15.7001 7.14506C15.8335 7.0148 15.9278 6.84984 15.9724 6.6688C16.017 6.48776 16.0102 6.29785 15.9525 6.12052C15.8949 5.94319 15.7889 5.78551 15.6464 5.66527C15.5039 5.54502 15.3306 5.46702 15.1461 5.44006L15.1441 5.43906Z" />
			</Jacket>
		);

	if (type === 'close')
		return (
			<Jacket
				height='24px'
				width='24px'
				viewBox='0 0 24 24'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				onClick={onClick}>
				<path d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z" />
			</Jacket>
		);

	console.error(
		`You've not added the correct 'type' prop to your <Icon /> component`
	);
	console.warn(
		`Remember to add "type='YOUR_ICON_NAME'" to your <Icon /> props or else your icon wont display`
	);
	return null;
};

export default Icon;
