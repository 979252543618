// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
function Noise() {
    return (
        <Jacket />
    );
}

export default Noise;