// Imports
// ------
import React from 'react';
import useSound from 'use-sound';
import Click from '@sounds/click.mp3';
import { useDato } from '../data';
import { menu, experienceModal } from '@states';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import { Jacket, Item, Link } from './styles';

// Component
// ------
const MenuOne = ({ isVisible }) => {
    // NOTE • Data
    const {
        page,
        exp
    } = useDato();

    // NOTE • Click Events
    const [play] = useSound(Click, { volume: 1 });

    const handleGalleries = action(() => {
        play();
        experienceModal.isActive = false;

        menu.galleries = true;
        [menu.experiences, menu.dogs, menu.shop, menu.contact] = false;
    });
    
    const handleExperiences = action(() => {
        play();
        experienceModal.isActive = false;

        menu.experiences = true;
        [menu.galleries, menu.dogs, menu.shop, menu.contact] = false;
    });

    return (
        <Jacket isVisible={isVisible}>
            <Item onClick={handleGalleries} activePage={menu.galleries}>
                <Link to="/galleries" className="ch">
                    <span data-label="Galleries">Galleries</span>
                    <span>{page.info.c}</span>
                </Link>
            </Item>
            <Item onClick={handleExperiences} activePage={menu.experiences}>
                <Link to="/experiences" className="ch">
                    <span data-label="Experiences">Experiences</span>
                    <span>{exp.info.c}</span>
                </Link>
            </Item>
        </Jacket>
    );
}

export default observer(MenuOne);