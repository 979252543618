// Imports
// ------
import React from 'react';
import Icon from '@icons';
import useSound from 'use-sound';
import Click from '@sounds/click.mp3';
import { menu } from '@states';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import { Jacket, Link } from './styles';

// Component
// ------
const Logo = ({ isVisible }) => {
    const [play] = useSound(Click, { volume: 1 });

    const handleMenus = action(() => {
        // console.log('clicked');
        play();
        [menu.isOpen, menu.experiences, menu.galleries, menu.shop, menu.contact] = false;
    });

    return (
        <Jacket onClick={handleMenus} isVisible={isVisible}>
            <Link to="/">
                <Icon type="brand" />
            </Link>
        </Jacket>
    );
}

export default observer(Logo);