// Imports
// ------
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageTransition from '@parts/Transitions'; // BUGFIX • This is bad - use the same name spaces unless you are NOT having an export default
import Noise from '@parts/Noise';
import Header from '@parts/Header';
import Loader from '@parts/Loader';
import Cursor from '@parts/Cursor';
import Audio from '@parts/Audio';
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions";
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from '@theme';
import { pagetrans, galleryCursor } from '@states';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useWindowSize } from 'react-use';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

// Styles
// ------
import '@css/global.css';

// Component
// ------
const Layout = ({ location, children }) => {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • Window Size Checker
	const { width } = useWindowSize();

	// NOTE • MIXPANEL
	const mixpanel = useMixpanel();

	// NOTE • Functions
	const setTrue = action(() => {
        pagetrans.isDefault = true;
		
		setTimeout(() => {
			pagetrans.isGallery = false;
			galleryCursor.needsRelease = false;
		}, 1000);
    });
	
	const setFalse = action(() => {
        pagetrans.isDefault = false;
		pagetrans.isGallery = false;
    });

	// NOTE • OnLoad: 100vh Fix
	useEffect(() => {
		const setHeightVar = () => {
			let vh = document.documentElement.clientHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		};

		// Init
		setHeightVar();

		const resizeChecker = () => {
			if (width > 768) {
				setHeightVar();
			}
		}

		// Initialize resize
		window.addEventListener('resize', resizeChecker);

		return () => {
			window.removeEventListener('resize', resizeChecker);
		};
	}, []);

	// NOTE • Mixpanel
	useEffect(() => {
		mixpanel.track(`Page: ${location.pathname}`);
	}, [location]);

	return (
		<TransitionProvider
			location={location}
			mode='successive'
			// y={0}
			enter={{
				opacity: 0,
				config: {
					duration: 1000,
					clamp: true,
				},
				onStart: () => {
					setTrue();
				}
			}}
			usual={{
				opacity: 1,
				config: {
					duration: 0,
					clamp: true,
				},
				onRest: () => {
					setFalse();
				}
			}}
			leave={{
				opacity: 0,
				config: {
					duration: 1000,
					clamp: true,
				},
				onStart: () => {
					setFalse();
				}
			}}>
			<ThemeProvider theme={theme}>
				<Noise />
				<Loader />
				{bp.large && (<Cursor location={location} />)}
				{bp.large && (<Audio />)}
				<Header />
				<PageTransition>
					<TransitionViews>
						{children}
					</TransitionViews>
				</PageTransition>
			</ThemeProvider>

			<GlobalStyle theme={theme} />
		</TransitionProvider>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default observer(Layout);
