// Imports
// ------
import { css } from 'styled-components';
import { breakpointUp } from './breakpoints';

// Exports
// --------------
// 1. Shared Heading Styles
// --------------
const sharedHeadingStyles = (props) => css`
	font-weight: ${props.theme.reg};
	font-family: ${props.theme.heading};
	text-transform: uppercase;
`;

// --------------
// 2. Heading Styles
// --------------
export const h1Styles = css`
	${sharedHeadingStyles}
	font-size: 4rem;
	line-height: 100%;

	${breakpointUp.medium` font-size: 4.8rem; `}
	${breakpointUp.large` font-size: 6rem; `}
    ${breakpointUp.xxlarge` font-size: 8rem; `}
`;

export const h2Styles = css`
	${sharedHeadingStyles}
	font-size: 3.5rem;
	line-height: 120%;

	${breakpointUp.medium` font-size: 3.6rem; `}
	${breakpointUp.large` font-size: 4.8rem; `}
    ${breakpointUp.xxlarge` font-size: 6rem; `}
`;

export const h3Styles = css`
	${sharedHeadingStyles}
	font-size: 3.2rem;
	line-height: 100%;

	${breakpointUp.medium` font-size: 3.4rem; `}
	${breakpointUp.large` font-size: 4rem; `}
    ${breakpointUp.xxlarge` font-size: 4.8rem; `}
`;

export const h4Styles = css`
	${sharedHeadingStyles}
	font-size: 1.8rem;
	line-height: 120%;

	${breakpointUp.large` font-size: 3.6rem; `}
`;

export const h5Styles = css`
	${sharedHeadingStyles}
	font-size: 2rem;
	line-height: 120%;

	${breakpointUp.large` font-size: 2.4rem; `}
`;

export const h6Styles = css`
	${sharedHeadingStyles}
	font-size: 1.6rem;
	line-height: 120%;

	${breakpointUp.large` font-size: 2.4rem; `}
`;

// --------------
// 3. Shared Body Styles
// --------------
const sharedBodyStyles = (props) => css`
	font-family: ${props.theme.body};
`;

// --------------
// 3. Body Styles
// --------------
export const pStyles = (props) => css`
	${sharedBodyStyles}
	color: ${props.theme.bc2};
	font-weight: ${props.theme.light};
	font-size: 1.6rem;
	line-height: 160%;

	${breakpointUp.medium` font-size: 1.8rem; `}
`;

export const spanStyles = (props) => css`
	${sharedBodyStyles}
	font-weight: ${props.theme.reg};
	font-size: 1.4rem;
	line-height: 160%;
`;

export const emStyles = (props) => css`
	${sharedBodyStyles}
	display: block;
	font-style: normal;
	font-weight: ${props.theme.semi};
	font-size: 1.4rem;
	line-height: 140%;
	text-transform: uppercase;
	letter-spacing: 0.12em;

	${breakpointUp.large` font-size: 1.6rem; `}
`;
