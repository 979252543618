// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { TransitionLink } from 'gatsby-plugin-transitions';

// Exports
// ------
export const Jacket = styled.ul`
    transition: all 1.2s ${props => props.theme.bezzy};
    transform: translateY(${props => props.isVisible ? `0` : `-12rem`});
`;

export const Item = styled.li(props => css`
    display: inline-flex;
    align-items: center;
    margin-right: 4.8rem;

    &:last-child { margin-right: 0; }

    a, a:visited {
        transition: all .4s ${props.theme.bezzy};
        position: relative;

        font-family: ${props.theme.heading};
        font-size: 1.4rem;
        line-height: 1.2;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: ${props.theme.bc1};

        &:before {
            transition: all .4s ${props.theme.bezzy};
            content: '';
            position: absolute;
            bottom: -1px;
            width: 0;
            height: 1px;
            background: ${props.theme.bc1};
        }

        ${props.activePage && css`
            &:before {
                width: 100%;
            }
        `}

        span:first-child {
            color: transparent;
            display: inline-flex;
            align-items: center;
            position: relative;
            overflow: hidden;
            height: 50%;

            &:before,
            &:after {
                content: attr(data-label);
                position: absolute;
                top: 50%; left: 0;
                color: ${props.theme.bc1};
                transition: all .5s ${props.theme.bezzy};
            }

            &:before {
                transform: translateY(-50%) skewY(0deg);
                opacity: 1;
            }

            &:after {
                transform: translateY(240%) skewY(-24deg);
                opacity: 0;
            }
        }

        span + span {
            position: absolute;
            transform: translateY(-1rem);
            font-size: 1.2rem;
            font-weight: bold;
            color: ${props.theme.white};
        }

        ${breakup.large`
            ${!props.activePage && css`
                &:hover {
                    filter: brightness(85%);

                    &:before { width: 100%; }

                    span:first-child {
                        &:before {
                            transform: translateY(-240%) skewY(-24deg);
                            opacity: 0;
                        }

                        &:after {
                            transform: translateY(-50%) skewY(0deg);
                            opacity: 1;
                        }
                    }
                }
            `}
        `}
    }
`);

export const Link = styled(TransitionLink)``;