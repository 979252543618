// Imports
// ------
import React from 'react';
import camera1 from '@images/camera4.gif';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Camera = ({ isVisible, notVisible }) => (
    <Jacket isVisible={isVisible} notVisible={notVisible}>
        <img src={camera1} alt="" />
    </Jacket>
);

export default Camera;