// Imports
// ------
import styled from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
export const Jacket = styled.div`
    position: fixed;
    top: 50%; left: 50%;
    z-index: 3;

    transition: all 1s ${props => props.theme.bezzy};
    transform: translate(-50%, -50%) scale(${props => props.isVisible ? `0.6` : `0`});
    transition-delay: 1.2s;
    filter: brightness(40%);

    ${breakup.medium`
        transform: translate(-50%, -50%) scale(${props => props.isVisible ? `1` : `0`});
    `}

    ${props => props.notVisible && `
        transition-delay: .4s;
        transform: translate(-50%, -50%) scale(0);
    `}
`;