// Imports
// -------
import { css, createGlobalStyle } from 'styled-components';

// *** Theme Styling *** //
// ------
// 1. NOTE • Social Colours
// ------
// 2. NOTE • Brand Colours
// ------
// 3. NOTE • Notification Colours
// ------
// 4. NOTE • Padding Sizes
// ------
// 5. NOTE • Margin Sizes
// ------
// 6. NOTE • Fonts
// ------
// 7. NOTE • Weights
// ------
// 8. NOTE • Breakpoints
// ------
// 9. NOTE • Easings
// ------
// 10. NOTE • Magic Verticals
// ------
// 11. NOTE • No ScrollBars
// ------
// 12. NOTE • Alignments
// ------

// Exports
// ------
export const theme = {
	// 1. ANCHOR • Social Colours
	// ------
	facebook: '#3b5998',
	twitter: '#1da1f2',
	creativeMarket: '#8ba753',
	slack: '#e9a820',
	skype: '#00aff0',
	instagram: '#833ab4',
	dribbble: '#ea4c89',
	behance: '#1769ff',
	linkedin: '#0077b5',

	// 2. ANCHOR • Notification Colours
	// ------
	pos: '#3adb76', // Green (Success / Positive)
	neg: '#cc4b37', // Red (Error / Negative)
	warn: '#ffae00', // Yellow (Warning / Neutral)

	// 3. ANCHOR • Brand Colours
	// ------
	white: '#ffffff',
	black: '#0D0C08',
	bc1: '#F4C00C', // Gold
	bc2: '#78674D', // Cardboard
	bc3: '#16140A', // Mud
	bc4: '#212121', // Light Black
	bc5: '#DEDEDE', // Light Grey
	bc6: '#8A8984', // Dark Blue
	bc7: '#4C6172', // Light Blue

	// 4. ANCHOR • Padding Sizes
	// ------
	mpad: '1.2rem', // Mobile Padding
	padS: '10rem', // Small
	padM: '12rem', // Medium
	padL: '20rem', // Large

	// 5. ANCHOR • Margin Sizes
	// ------
	marS: '6rem', // Small
	marM: '12rem', // Medium
	marL: '20rem', // Large

	// 6. ANCHOR • Fonts
	// ------
	heading: "'Brandon Grotesque', 'Helvetica', 'Arial', Serif;",
	// mono: "'CHANGE__ME', 'Helvetica', 'Arial', Monospace;",
	// hand: "'CHANGE__ME', 'Helvetica', 'Arial', Sans-Serif;",
	body: "'Inter', 'Helvetica', 'Arial', Sans-Serif;",
	fancy: "'Canopee', 'Helvetica', 'Arial', Sans-Serif;",

	// 7. ANCHOR • Weights
	// ------
	light: 300,
	reg: 400,
	semi: 500,
	bold: 600,

	// 8. ANCHOR • Breakpoints
	// ------
	small: '0px',
	smedium: '370px',
	medium: '700px',
	large: '1024px',
	xlarge: '1200px',
	xxlarge: '1400px',
	huge: '1600px',
	uber: '1800px',

	gutterSmall: '24px',
	gutterLarge: '36px',
	maxGridSize: '1440px',

	// 9. ANCHOR • Easings
	// ------
	bezzy: 'cubic-bezier(0.8, 0, 0, 1)',
	ease: 'all .3s ease-in-out',

	// 10. ANCHOR • Magic Verticals
	// ------
	vh: css`
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	`,

	// 11. ANCHOR • No ScrollBars
	// ------
	noscrollbars: css`
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE 10+ */

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent; /* Chrome/Safari/Webkit */
		}
	`,

	// 12. ANCHOR • Alignments
	// ------
	valign: css`
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	`,

	valignReset: css`
		position: relative;
		top: auto;
		transform: none;
	`,

	talign: css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`,

	talignReset: css`
		position: relative;
		top: auto;
		left: auto;
		transform: none;
	`,
};

export const GlobalStyle = createGlobalStyle`
    body {
        background: ${theme.black};
		color: ${theme.white};
    }
`;
