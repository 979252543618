// Imports
// ------
import React from 'react';
import useSound from 'use-sound';
import Click from '@sounds/click.mp3';
import { menu, experienceModal } from '@states';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import { Jacket, Item, Link } from './styles';

// Component
// ------
const MenuTwo = ({ isVisible }) => {
    // NOTE • Click Events
    const [play] = useSound(Click, { volume: 1 });

    const handleDogs = action(() => {
        play();
        experienceModal.isActive = false;

        menu.dogs = true;
        [menu.galleries, menu.shop, menu.experiences, menu.contact] = false;
    });

    const handleShop = action(() => {
        play();
        experienceModal.isActive = false;

        menu.shop = true;
        [menu.galleries, menu.experiences, menu.dogs, menu.contact] = false;
    });
    
    const handleContact = action(() => {
        play();
        experienceModal.isActive = false;

        menu.contact = true;
        [menu.galleries, menu.dogs, menu.shop, menu.experiences] = false;
    });

    return (
        <Jacket isVisible={isVisible}>
            <Item onClick={handleDogs} activePage={menu.dogs}>
                <Link to="/dog-photography" className="ch">
                    <span data-label="Dog Photoshoots">Dog Photoshoots</span>
                    <span></span>
                </Link>
            </Item>
            <Item onClick={handleShop} activePage={menu.shop}>
                <Link to="/shop" className="ch">
                    <span data-label="Shop">Shop</span>
                    <span></span>
                </Link>
            </Item>
            <Item onClick={handleContact} activePage={menu.contact}>
                <Link to="/contact" className="ch">
                    <span data-label="Contact">Contact</span>
                    <span></span>
                </Link>
            </Item>
        </Jacket>
    );
}

export default observer(MenuTwo);