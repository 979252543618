// Imports
// ------
import { css } from 'styled-components';
import { breakpointUp } from './breakpoints';

// Exports
// --------------
// 1. Margin
// --------------
const marginStyles = (props) => css`
	${props.marBottom &&
	css`
		margin-bottom: ${props.theme.padS};

		${breakpointUp.medium` margin-bottom: ${props.theme.padM}; `}
		${breakpointUp.large` margin-bottom: ${props.theme.padL}; `}
	`}

	${props.marTop &&
	css`
		margin-top: ${props.theme.padS};

		${breakpointUp.medium` margin-top: ${props.theme.padM}; `}
		${breakpointUp.large` margin-top: ${props.theme.padL}; `}
	`}
    
    ${props.mar &&
	css`
		margin-top: ${props.theme.padS};
		margin-bottom: ${props.theme.padS};

		${breakpointUp.medium`
            margin-top: ${props.theme.padM};
            margin-bottom: ${props.theme.padM};
        `}

		${breakpointUp.large`
            margin-top: ${props.theme.padL};
            margin-bottom: ${props.theme.padL};
        `}
	`}
`;

// --------------
// 2. Padding
// --------------
const paddingStyles = (props) => css`
	${props.mpad &&
	css`
		padding-left: ${props.theme.mpad};
		padding-right: ${props.theme.mpad};

		${breakpointUp.large`
            padding-left: 0px;
            padding-right: 0px;
        `}
	`}

	${props.mpadLarge &&
	css`
		padding-left: ${props.theme.mpad};
		padding-right: ${props.theme.mpad};
	`}

    ${props.padBottom &&
	css`
		padding-bottom: ${props.theme.padS};

		${breakpointUp.medium` padding-bottom: ${props.theme.padM}; `}
		${breakpointUp.large` padding-bottom: ${props.theme.padL}; `}
	`}

    ${props.padTop &&
	css`
		padding-top: ${props.theme.padS};

		${breakpointUp.medium` padding-top: ${props.theme.padM}; `}
		${breakpointUp.large` padding-top: ${props.theme.padL}; `}
	`}
    
    ${props.pad &&
	css`
		padding-top: ${props.theme.padS};
		padding-bottom: ${props.theme.padS};

		${breakpointUp.medium`
            padding-top: ${props.theme.padM};
            padding-bottom: ${props.theme.padM};
        `}

		${breakpointUp.large`
            padding-top: ${props.theme.padL};
            padding-bottom: ${props.theme.padL};
        `}
	`}
`;

// --------------
// 7. Export: Container Styles
// --------------
export const semantics = css`
	${paddingStyles}
	${marginStyles}
`;
