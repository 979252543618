// Imports
// ------
import styled, { css } from 'styled-components';
import { Nav } from '@tackl';
import { TransitionLink } from 'gatsby-plugin-transitions';

// Exports
// ------
export const Jacket = styled(Nav)( (p) => css`
    ${p.theme.vh}

    transition: all .3s ${p.theme.bezzy};
    position: fixed;
    z-index: 11;
    top: 0; left: 0; right: 0;
    transform: translateY(${p.isOpen ? 0 : `-100vh`});
    transition-delay: ${p.isOpen ? `0s` : `1.4s`};

    &:before, &:after {
        content: '';
        ${p.theme.vh}

        position: fixed;
        top: 0; left: 0; right: 0;
        transform: translateY( ${p.isOpen ? 0 : `-100vh`});
    }
    
    &:before {
        transition: all 1s ${p.theme.bezzy};
        transition-delay: ${p.isOpen ? `.2s` : `.5s`};
        z-index: 1;
        
        background: ${p.theme.bc1};
    }
    
    &:after {
        transition: all 1.3s ${p.theme.bezzy};
        transition-delay: ${p.isOpen ? `.25s` : `.2s`};
        z-index: 2;
        
        background: ${p.theme.bc2};
    }
`);

export const MenuList = styled.ul((p) => css`
    position: relative;
    z-index: 4;

    transition: all .4s ${p.theme.bezzy};
    transition-delay: ${p.isOpen ? `.4s` : `.7s`};
    opacity: ${p.isOpen ? 1 : 0};

    overflow: hidden;

    display: flex;
    flex-direction: column;
    list-style: none;

    padding-top: 12rem;
    padding-left: 2.4rem;
`);


// NOTE • DELAYED ANIMATIONS
const items = 5
const duration = 1.25

function template(i, items, duration) {
    return `
        &:nth-child(${i + 1}) {
          transition-delay: ${`${(duration / items) * i}s`};
        }
    `;
}
function getAnimations(items, duration) {
    let str = '';
    for (let i = 0; i < items; i += 1) {
        str += template(i, items, duration)
    }
    return str;
}

export const Item = styled.li(
    (p) => css`
        position: relative;

        transition: all 1.2s ${p.theme.bezzy};
        transform: translateX(${p.isOpen ? 0 : `-100vh`});

        display: flex;
        margin-bottom: 3.6rem;

        ${getAnimations(items, duration)}

        a {
            color: ${p.activePage ? p.theme.bc1 : p.theme.white} !important;
        }
    `
);

export const Link = styled(TransitionLink)(
    (props) => css`
        transition: all 0.6s ${props.theme.bezzy};
        position: relative;
        display: flex;

        font-family: ${props.theme.body};
        font-size:  2.4rem;
        font-weight: ${props.theme.reg};

        &:visited {
            color: ${props.theme.white};
        }

        span {
            font-size: 1.2rem;
            font-weight: 700;
            margin-left: .6rem;
        }

        ${props.disabled && css`
            pointer-events: none;
            filter: grayscale(1);
            opacity: 0.24;

            &:before {
                transition: all .4s ${props.theme.bezzy};
                content: '';
                position: absolute;
                height: 1px;
                background: ${props.theme.bc1};
                width: 100%;
                bottom: 50%;
            }
        `}
    `
);
