// Imports
// ------
import { observable } from 'mobx';

// Exports
// ------
export const pagetrans = observable({
	isDefault: false,
	isGallery: false,
	isHolding: false,
	isShutter: false,

	galleryImage: null,
	galleryImageAlt: null,
});

export const galleryCursor = observable({
	needsRelease: false,
});

export const galleryModal = observable({
	isActive: false,
	activeSlide: 0,
});

export const experienceModal = observable({
	isActive: false,
	selectedExpTitle: "",
	selectedExpDate: "",
	selectedExpPrice: "",
});

export const body = observable({
	isLocked: false,
});

export const menu = observable({
	isOpen: false,

	galleries: false,
	experiences: false,
	dogs: false,
	shop: false,
	contact: false,
});

export const loader = observable({
	isComplete: false,
});

export const modal = observable({
	isOpen: false,
});
