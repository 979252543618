// Imports
// ------
import styled, { keyframes } from 'styled-components';
import noiseImage from './noise.png';

// Exports
// ------
const noiseAnimation = keyframes`
    0% {
        transform: translate(0)
    }
    10% {
        transform: translate(-5%,-5%)
    }
    20% {
        transform: translate(-10%,5%)
    }
    30% {
        transform: translate(5%,-10%)
    }
    40% {
        transform: translate(-5%,15%)
    }
    50% {
        transform: translate(-10%,5%)
    }
    60% {
        transform: translate(15%)
    }
    70% {
        transform: translateY(10%)
    }
    80% {
        transform: translate(-15%)
    }
    90% {
        transform: translate(10%,5%)
    }
    to {
        transform: translate(5%)
    }
`;

export const Jacket = styled.div`
    position: fixed;
    z-index: 999999;
    top: 0; left: 0; right: 0; bottom: 0;

    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.5;

    &:before {
        content: '';
        will-change: transform;
        animation: ${noiseAnimation} 1s steps(4) infinite;
        background-image: url(${noiseImage});
        width: 200%;
        height: 200%;
        display: block;
        position: absolute;
        top: -50%;
        left: -50%;
    }
`;