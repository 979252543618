// Imports
// ------------
import styled, { css, keyframes } from 'styled-components';
// import { breakup, Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled.div`
    position: fixed;
    z-index: 120;
    bottom: 3.6rem; left: 50%;

    transition: all 2s ${props => props.theme.bezzy};
    transform: translateX(-50%) translateY(${props => props.isActive ? `0` : `12rem`});
    transition-delay: 1s;
`;

const shift = (props) => keyframes`
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-20px);
    }
`;

export const Button = styled.button((props) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background: transparent;
    cursor: pointer;

    span {
        margin-right: 1.2rem;

        font-family: ${props.theme.heading};
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 1.4rem;
    }

    em {
        position: relative;
        width: 2.4rem;
        height: 2.4rem;

        svg {
            &.squiggle {
                opacity: ${props.isActive ? 1 : 0};

                path {
                    animation: ${shift} 1s linear infinite;
                    animation-play-state: running;
                }
            }
            
            &.line {
                position: absolute;
                left: 0;
                opacity: ${props.isActive ? 0 : 1};
                
                path {
                    fill: white;
                }
            }
        }
    }
`);