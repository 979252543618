// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import Topo from '@images/ps-topo-bg.png';

// Exports
// ------------
export const Jacket = styled.div((props) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    overflow: hidden;

    background: ${props.theme.bc3};

    clip-path: ellipse(${props.isDefault ? `120vw 120vw` : `0vw 0vw`} at 50% 50%);
    filter: blur(${props.isDefault ? 0 : 2.4}rem);
    transition: clip-path 1.1s ${props.theme.bezzy}, filter 0.5s linear;
    transition-delay: ${props.isDefault ? 0.25 : 0}s;

    ${breakup.xxlarge`
        clip-path: ellipse(${props.isDefault ? `200vw 200vw` : `0vw 0vw`} at 50% 50%);
    `}
`);


export const GalleryJacket = styled.div((props) => css`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    z-index: 1;
    overflow: hidden;
    pointer-events: none;

    ${props => props.theme.talign};
    width: 100%;
    height: 100%;

    transition-delay: .3s;
    transition: clip-path 1.2s ${props => props.theme.bezzy};
    clip-path: inset(${(props) => props.isActive ? `0% 0% 0% 0%` : `50% 0% 50% 0%`});

    background: ${props.theme.bc1};

    &:before {
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        
        width: 100%;
        height: 100vh;

        z-index: 2;
        
        background: ${props.theme.black};

        transition: clip-path 1.1s ${props => props.theme.bezzy};
        transition-delay: .2s;
        clip-path: inset(${(props) => props.isActive ? `0% 0% 0% 0%` : `50% 0% 50% 0%`});
    }

    > div {
        width: 24rem;
        height: 24rem;
        transition-delay: .2s;
        z-index: 6;

        img {
            width: 100%;
            height: 100%;
        }
    }

    > div + div {
        width: 36rem;
        height: 36rem;
        z-index: 3;
    }
`);


export const Topographic = styled.div((p) => css`
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url(${Topo}) repeat;

    transition: all 1.2s ${p.theme.bezzy};
    transition-delay: .8s;
    transform-origin: center center;
    
    max-height: 0;

    ${p.isVisible && css`
        width: 70%;
        max-height: 18rem;
        ${breakup.medium` max-height: 40rem; `}
    `}
    
    ${p.notVisible && `max-height: 0;`}
`);