// Imports
// ------------
import React from 'react';
// import { GatsbyImage } from 'gatsby-plugin-image';
import Camera from '@parts/Loader/Camera';
import { pagetrans } from '@states';
import { observer } from 'mobx-react-lite';

// Styles
// ------------
import { Jacket, GalleryJacket, Topographic } from './styles';

// Component
// ------------
const PageTransition = ({ children }) => {
    return (
        <Jacket
            isDefault={pagetrans.isDefault}
        >
            <GalleryJacket isActive={pagetrans.isGallery}>
                {/* <GatsbyImage image={pagetrans.galleryImage} alt={pagetrans.galleryImageAlt} /> */}
                <Camera isVisible={pagetrans.isGallery} />
                <Topographic isVisible={pagetrans.isGallery} />
            </GalleryJacket>
            {children}
        </Jacket>
    );
}

export default observer(PageTransition);