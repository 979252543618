// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Header } from '@tackl';

// Exports
// ------
export const Jacket = styled(Header)((p) => css`
    position: fixed;
    width: 100%;
    padding: 2.4rem;
    z-index: 99999999;

    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: all 1.2s ${p.theme.bezzy};
    transform: translateY(${p.isHidden ? `-12rem` : `0`});

    ${breakup.medium` padding: 3.6rem; `}
    ${breakup.large` padding: 4.8rem 6rem; `}
`);