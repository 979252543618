// Imports
// ------------
import React from 'react';
import { menu } from '@states';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';

// Styles
// ------------
import { ToggleJacket, Jacket } from './styles';

// Component
// ------------
const MenuToggle = ({ isVisible }) => {
    const toggleMenu = action(() => {
        menu.isOpen = !menu.isOpen;
    });

    return (
        <ToggleJacket isVisible={isVisible}>
            <Jacket isOpen={menu.isOpen} onClick={toggleMenu}>
                <span />
                <span />
            </Jacket>
        </ToggleJacket>
    );
}

export default observer(MenuToggle);
