// Imports
// ------
import styled, { css, keyframes } from 'styled-components';

// Exports
// ------
const rotate = (props) => keyframes`
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
`;

export const Jacket = styled.div(
	(props) => css`
		position: fixed;

		z-index: 99999;
		pointer-events: none;
		overflow: visible;

		width: 1.6rem;
		height: 1.6rem;
		will-change: transform;
		transform-origin: center center;

		&:before {
			content: '';
			position: absolute;
			z-index: 2;
			top: 50%; left: 50%;
			transform: translate(-50%, -50%) rotate(0deg);
			transform-origin: center center;

			width: 100%;
			height: 100%;

			border-radius: 100%;
			border-style: solid;
			border-width: 2px;
			border-color: ${props.theme.bc1};
			background: ${props.theme.bc1};

			transition: ${props.theme.ease};

			animation: ${rotate} 24s linear infinite both;
			animation-play-state: paused;
		}

		&:after {
			content: '';
			position: absolute;
			z-index: 1;
			top: 50%; left: 50%;
			transform: translate(-50%, -50%);
			transform-origin: center center;

			width: 0rem;
			height: 0rem;
			
			transition: ${props.theme.ease};
		}

		span {
			transition: ${props.theme.ease};
			transition-delay: .2s;
			opacity: 0;

			position: absolute;
			z-index: 2;
			top: 50%; left: 50%;
			transform: translate(-50%, -50%) scale(0.5);
			transform-origin: center center;

			display: flex;
			align-items: center;
			justify-content: center;
			width: 8rem;
			height: 8rem;

			color: ${props.theme.white};
			text-transform: uppercase;
			font-family: ${props.theme.heading};
			font-size: 1.2rem;
			line-height: 1.2;
			letter-spacing: 1px;
			font-weight: 500;
			text-align: center;

			${props.hoverType === 'hold' && `
				opacity: 1;
				transform: translate(-50%, -50%) scale(1);
			`}

			${props.hoverType === 'prev' && `
				opacity: 1;
				transform: translate(-50%, -50%) scale(1);
			`}

			${props.hoverType === 'next' && `
				opacity: 1;
				transform: translate(-50%, -50%) scale(1);
			`}

			${props.hoverType === 'close' && `
				opacity: 1;
				transform: translate(-50%, -50%) scale(1);
			`}
		}


		${props.hoverType === 'link' && `
			&:before {
				background: transparent;
				width: 150%;
				height: 150%;

				${props.isPressed && `
					width: 200%;
					height: 200%;
				`}
			}
		`}

		${props.hoverType === 'button' && `
			&:before {
				background: transparent;
				width: 150%;
				height: 150%;
				border-color: ${props.theme.black};

				${props.isPressed && `
					width: 200%;
					height: 200%;
				`}
			}
		`}

		${props.hoverType === 'hold' && `
			&:before {
				background: transparent;
				border-style: dashed;
				border-color: ${props.theme.bc2};
				width: 14rem;
				height: 14rem;
				animation-play-state: running;

				${props.isPressed && `
					width: 10rem;
					height: 10rem;
				`}
			}

			&:after {
				background: ${props.theme.bc3};
				border-radius: 100%;
				width: 11rem;
				height: 11rem;
				opacity: 1;
			}
		`}

		${props.hoverType === 'prev' && `
			&:before {
				transform-origin: center center;
				background: transparent;
				border-style: dashed;
				border-color: ${props.theme.bc2};
				width: 8rem;
				height: 8rem;
				animation-play-state: running;

				${props.isPressed && `
					width: 10rem;
					height: 10rem;
				`}
			}

			&:after {
				transform-origin: center center;
				background: ${props.theme.bc3};
				border-radius: 100%;
				width: 6rem;
				height: 6rem;
				opacity: 1;
			}

			span {
				svg {
					width: 2.4rem;
					height: 2.4rem;
					fill: ${props.theme.white};
				}
			}
		`}

		${props.hoverType === 'next' && `
			&:before {
				background: transparent;
				border-style: dashed;
				border-color: ${props.theme.bc2};
				width: 8rem;
				height: 8rem;
				animation-play-state: running;

				${props.isPressed && `
					width: 10rem;
					height: 10rem;
				`}
			}

			&:after {
				background: ${props.theme.bc3};
				border-radius: 100%;
				width: 6rem;
				height: 6rem;
				opacity: 1;
			}

			span {
				svg {
					width: 2.4rem;
					height: 2.4rem;
					fill: ${props.theme.white};
				}
			}
		`}

		${props.hoverType === 'close' && `
			&:before {
				background: transparent;
				border-style: dashed;
				border-color: ${props.theme.bc2};
				width: 8rem;
				height: 8rem;
				animation-play-state: running;

				${props.isPressed && `
					width: 10rem;
					height: 10rem;
				`}
			}

			&:after {
				background: ${props.theme.bc3};
				border-radius: 100%;
				width: 6rem;
				height: 6rem;
				opacity: 1;
			}

			span {
				svg {
					width: 2.4rem;
					height: 2.4rem;
					fill: ${props.theme.white};
				}
			}
		`}
	`
);
