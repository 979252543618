// Imports
// ------
import React, { useEffect, useState } from 'react';
import Logo from './Logo';
import MenuOne from './MenuOne';
import MenuTwo from './MenuTwo';
import MenuToggle from './MenuToggle';
import MobileMenu from './MobileMenu';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { loader, galleryModal } from '@states';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const Header = () => {
	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • States
	const [logoVisible, setLogoVisible] = useState(false);
	const [menuVisible, setMenuVisible] = useState(false);
	
	// NOTE • Effects
	useEffect(() => {
		setTimeout(() => {
			setLogoVisible(true);
		}, loader.isComplete ? 1000 : 2500);

		setTimeout(() => {
			setMenuVisible(true);
		}, loader.isComplete ? 1500 : 6000);
	}, []);

	return (
		<Jacket isHidden={galleryModal.isActive}> 
			{bp.large && <MenuOne isVisible={menuVisible} />}
			<Logo isVisible={logoVisible} />
			{bp.large ? (
				<MenuTwo isVisible={menuVisible} />
			) : (
				<>
					<MenuToggle isVisible={logoVisible} />
					<MobileMenu />
				</>
			)}
		</Jacket>
	)
}

export default observer(Header);
