// Imports
// ------
import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import Icon from '@icons';
import { menu, pagetrans, galleryCursor } from '@states';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
function Cursor({ location }) {
	// NOTE • Refs
	const cursor = useRef();

	// NOTE • States
	const [hoverType, setHoverType] = useState('std');
	const [pressed, setPressed] = useState(false);

	// NOTE • Initializer
	const init = () => {
		// NOTE • Initial Setup
		gsap.set(cursor.current, {
			xPercent: -50,
			yPercent: -50,
		});

		const pos = {
			x: window.innerWidth / 2,
			y: window.innerHeight / 2,
		};

		const mouse = {
			x: pos.x,
			y: pos.y,
		};

		const speed = 0.5;

		const xSet = gsap.quickSetter(cursor.current, 'x', 'px');
		const ySet = gsap.quickSetter(cursor.current, 'y', 'px');

		window.addEventListener('mousemove', (e) => {
			mouse.x = e.x;
			mouse.y = e.y;
		});

		gsap.ticker.add(() => {
			const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());
			pos.x += (mouse.x - pos.x) * dt;
			pos.y += (mouse.y - pos.y) * dt;
			xSet(pos.x);
			ySet(pos.y);
		});
	};

	// NOTE • Standard Hover
	const activateHover = () => {
		setHoverType('link');
	};
	
	// NOTE • Button Hover
	const activateButtonHover = () => {
		setHoverType('button');
	};

	// NOTE • Press Hold Hover
	const activateHold = () => {
		setHoverType('hold');
	};

	// NOTE • Swiper Prev Hover
	const activateSwiperPrev = () => {
		setHoverType('prev');
	};

	// NOTE • Swiper Next Hover
	const activateSwiperNext = () => {
		setHoverType('next');
	};

	// NOTE • Swiper Close Hover
	// const activateSwiperClose = () => {
	// 	setHoverType('close');
	// };

	// NOTE • Standard Pressed State
	const activatePressed = () => {
		setPressed(true);
	};
	
	const killPressed = () => {
		setPressed(false);
	};

	// NOTE • Kill Function
	const killAll = () => {
		setHoverType('std');
		setPressed(false);
	};

	// NOTE • Activate
	const activate = () => {
		// NOTE • Default Cursor
		const defaults = document.querySelectorAll('.ch');

		defaults.forEach((b) => {
			b.addEventListener('mouseover', activateHover);
			b.addEventListener('mousedown', activatePressed);
			b.addEventListener('mouseup', killPressed);
			b.addEventListener('mouseleave', killAll);
		});
		
		
		// NOTE • Buttons Cursor
		const buttons = document.querySelectorAll('.chb');

		buttons.forEach((b) => {
			b.addEventListener('mouseover', activateButtonHover);
			b.addEventListener('mousedown', activatePressed);
			b.addEventListener('mouseup', killPressed);
			b.addEventListener('mouseleave', killAll);
		});

		
		// NOTE • Pres & Hold Cursor
		const presshold = document.querySelectorAll('.hold');

		presshold.forEach((b) => {
			b.addEventListener('mouseover', activateHold);
			b.addEventListener('mousedown', activatePressed);
			b.addEventListener('mouseup', killPressed);
			b.addEventListener('mouseleave', killAll);
		});
		

		// NOTE • Swiper Nav Prev Cursor
		const swiperPrev = document.querySelectorAll('.swiperPrev');

		swiperPrev.forEach((b) => {
			b.addEventListener('mouseover', activateSwiperPrev);
			b.addEventListener('mousedown', activatePressed);
			b.addEventListener('mouseup', killPressed);
			b.addEventListener('mouseleave', killAll);
		});

		// NOTE • Swiper Nav Prev Cursor
		const swiperNext = document.querySelectorAll('.swiperNext');

		swiperNext.forEach((b) => {
			b.addEventListener('mouseover', activateSwiperNext);
			b.addEventListener('mousedown', activatePressed);
			b.addEventListener('mouseup', killPressed);
			b.addEventListener('mouseleave', killAll);
		});

		// NOTE • Swiper Close Modal Cursor
		// const swiperClose = document.querySelectorAll('.swiperClose');

		// swiperClose.forEach((b) => {
		// 	b.addEventListener('mouseover', activateSwiperClose);
		// 	b.addEventListener('mousedown', activatePressed);
		// 	b.addEventListener('mouseup', killPressed);
		// 	b.addEventListener('mouseleave', killAll);
		// });
	};

	// NOTE • Onload: Initiate
	useEffect(() => {
		init();
	}, []);

	// NOTE • Onreload: ReActivate
	useEffect(() => {
		const timer = setTimeout(() => {
			activate();
		}, 2000);

		return () => {
			killAll();
			clearTimeout(timer);
		};
	}, [location.pathname, menu.isOpen, pagetrans.isActive]);

	return (
		<Jacket ref={cursor} hoverType={hoverType} isPressed={pressed}>
			<span>
				{hoverType === 'hold' && (
					<>
						{
							galleryCursor.needsRelease ?
								`Release!`
							:
							!galleryCursor.needsRelease && pressed ?
								`Keep holding!`
							:
							<>Press &amp; hold to enter</>
						}
					</>
				)}

				{hoverType === 'prev' && (
					<Icon type="left" />
				)}

				{hoverType === 'next' && (
					<Icon type="right" />
				)}

				{/* {hoverType === 'close' && (
					<Icon type="close" />
				)} */}
			</span>
		</Jacket>
	);
}

export default observer(Cursor);
