// Imports
// ------
import styled from 'styled-components';
import { breakup } from '@tackl';
import { TransitionLink } from 'gatsby-plugin-transitions';

// Exports
// ------
export const Jacket = styled.div`
    transition: all 1.2s ${props => props.theme.bezzy};
    transform: translateY(${props => props.isVisible ? `0` : `-12rem`});

    z-index: 12;
    
    ${breakup.large`
        position: absolute;
        left: 50%;
        transform: translateY(${props => props.isVisible ? `0` : `-12rem`}) translateX(-50%);
    `}

    svg {
        width: 6rem;
        height: 6rem;
    }
`;

export const Link = styled(TransitionLink)``;