// Imports
// ------
import styled from 'styled-components';

// Exports
// ------
export const Jacket = styled.svg`
    width: 2.4rem;
    height: 2.4rem;

    fill: white;
`;