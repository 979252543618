// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
    const data = useStaticQuery(
        graphql`
			query GlobalNavigation {
                page: allDatoCmsGallery {
                    info: pageInfo {
                        c: itemCount
                    }
                }
                
                exp: allDatoCmsExperience {
                    info: pageInfo {
                        c: itemCount
                    }
                }
			}
		`
    );
    return data;
};
