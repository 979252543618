// Imports
// ------
import React, { useEffect, useState } from 'react';
import Camera from './Camera';
import { loader } from '@states';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';

// Styles
// ------
import { Jacket, PartOne, PartTwo, TitleJacket, TitleText, TitleSub, Box, Word, Wild, Topographic } from './styles';

// Component
// ------
const LoadingIntro = () => {
    // NOTE • States
    const [isP1Visible, setIsP1Visible] = useState(true);
    const [isP2Visible, setIsP2Visible] = useState(false);
    const [hideP2Parts, setHideP2Parts] = useState(false);

    useEffect(() => {
        let P1Visibility;
        let P2Visibility;
        let isComplete;

        // NOTE • Loader State Action
        const setLoaderState = action(() => {
            loader.isComplete = true;
        });

        if (!loader.isComplete) {
            clearTimeout(P1Visibility);
            clearTimeout(P2Visibility);
            clearTimeout(isComplete);

            // NOTE • Make the first intro part animate out
            P1Visibility = setTimeout(() => {
                setIsP1Visible(false);
                setIsP2Visible(true);
            }, 2000);

            // NOTE • When part is done after 2 seconds, part two comes in, then lets transition it out and close the loader
            P2Visibility = setTimeout(() => {
                setIsP2Visible(false);
                setHideP2Parts(true);
            }, 5000);

            isComplete = setTimeout(() => {
                runInAction(setLoaderState);
            }, 6500);
        }
    }, []);

    return (
        <Jacket isComplete={loader.isComplete}>

            {!loader.isComplete && (
                <>
                    <PartOne isVisible={isP1Visible}>
                        <TitleJacket>
                            <TitleText isVisible={isP1Visible}>
                                <span>Award Winning</span>
                                <span>Nature Photographer</span>
                            </TitleText>
                            <TitleSub isVisible={isP1Visible}>
                                <span>David Roberts</span>
                            </TitleSub>
                        </TitleJacket>
                    </PartOne>

                    <PartTwo isVisible={isP2Visible}>
                        <Box isVisible={isP2Visible} notVisible={hideP2Parts} />
                        <Word topLeft isVisible={isP2Visible} notVisible={hideP2Parts}>Taking</Word>
                        <Word bottomRight isVisible={isP2Visible} notVisible={hideP2Parts}>Control</Word>
                        <Wild isVisible={isP2Visible} notVisible={hideP2Parts}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350.4 63.6">
                                <path d="M33.09,3.54S44.2.4,52.2,1.4" />
                                <line x1="9.2" y1="62.4" x2="44.2" y2="1.4" />
                                <path d="M1.2,62.4a78.9,78.9,0,0,1,17-2" />
                                <path d="M47.2,28.4s-18,21-13,21,18-21,25-21-7,13-3,19,24-6,28-13" />
                                <path d="M125.36,6.48S100.21,46.36,96.2,57.88" />
                                <path d="M137.49,6.28s-35.07,53.65-28.18,51.88C112.2,53.4,129.2,26.4,135.2,28.4s-3,16,2,20,21-7,26-14,12-7,10-3-6,7.11-13,8.05c0,0-4,9.95,3,10.95s20-7,27-17" />
                                <path d="M97.36,21.25S160.2,13.4,141.2,19.4" />
                                <path d="M214.2,33.4s-6,17-1,19,14-16,12-15c-4.82,2.4-4,16,0,17,2.91.72,6-2,12-10,5-8,6.42-17.14,3-16-3,1-6,9-3,14,3.29,5.49,11,2,11,2s8-2,15-15c0,0-10,15-4,18s16-2,25-10c0,0,16-28,20-33,0,0-29,45-24,52,0,0,1,2,6-1,5.75-3.45,12-10,12-10" />
                                <path d="M322.2,33.4s-1-6-6-4-10.55,5-18.27,18c0,0-1.73,4,1.27,3s23-16,32-27,12-17,9-16-11,7-16,22-3,19,1,19,18-5,24-15" />
                                <line x1="268.2" y1="20.4" x2="266.2" y2="23.4" />
                            </svg>
                        </Wild>
                        <Camera isVisible={isP2Visible} notVisible={hideP2Parts} />
                        <Topographic isVisible={isP2Visible} notVisible={hideP2Parts} />
                    </PartTwo>
                </>
            )}

        </Jacket>
    );
}

export default observer(LoadingIntro);