// Imports
// ------------
import React, { useState, useEffect } from 'react';
import useSound from 'use-sound';
import Environment from '@sounds/environment.mp3';
import { loader } from '@states';
import { observer } from 'mobx-react-lite';
import { action } from 'mobx';

// Styles
// ------------
import { Jacket, Button } from './styles';

// Component
// ------------
const Audio = () => {
    // NOTE • States
    const [isPlaying, setIsPlaying] = useState(false);

    // NOTE • Functions
    const [play, { stop }] = useSound(Environment, {
        volume: 1,
        loop: true
    });

    const handlePlay = action(() => {
        if(isPlaying) {
            stop();
            setIsPlaying(false);
        } else {
            play();
            setIsPlaying(true);
        }
    });

    // NOTE • M
    useEffect(() => {
        const handleFocus = action(() => {
            // console.log('Tab has focus');
            play();
            setIsPlaying(true);
        });

        const handleBlur = action(() => {
            // console.log('Tab lost focus');
            stop();
            setIsPlaying(false);
        })

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, [play, stop, handlePlay]);

    // useEffect(() => {
    //     loader.isComplete && isMounted && play();
    //     loader.isComplete && setIsPlaying(true);
    // }, [loader.isComplete]);

    return (
        <Jacket isActive={loader.isComplete}>
            <Button
                isActive={isPlaying}
                onClick={handlePlay}
            >
                <span>Audio</span>
                <em>
                    <svg 
                        xmlns="http://www.w3.org/2000/svg"
                        id="squiggle-link"
                        width="24"
                        height="24"
                        viewBox="0 0 20 4"
                        className="squiggle">
                        <path 
                            fill="none"
                            stroke="#ffffff"
                            strokeWidth="2"
                            d="M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3"
                        />
                    </svg>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="line">
                        <path d="M23 13H1C0.4 13 0 12.6 0 12C0 11.4 0.4 11 1 11H23C23.6 11 24 11.4 24 12C24 12.6 23.6 13 23 13Z" />
                    </svg>
                </em>
            </Button>
        </Jacket>
    );
}

export default observer(Audio);