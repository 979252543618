// Imports
// -----
import React from 'react';
import { useDato } from '../data';
import { menu } from '@states';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';

// Styles
// ------
import { Jacket, MenuList, Item, Link } from './styles';

// Component
// ------
const MobileMenu = () => {
    // NOTE • Data
    const {
        page,
        exp
    } = useDato();

    // NOTE • Click Events
    const handleGalleries = action(() => {
        menu.isOpen = !menu.isOpen;
        menu.galleries = true;
        [menu.experiences, menu.dogs, menu.shop, menu.contact] = false;
    });
    
    const handleExperiences = action(() => {
        menu.isOpen = !menu.isOpen;
        menu.experiences = true;
        [menu.galleries, menu.dogs, menu.shop, menu.contact] = false;
    });
    
    const handleDogs = action(() => {
        menu.isOpen = !menu.isOpen;
        menu.dogs = true;
        [menu.galleries, menu.experiences, menu.shop, menu.contact] = false;
    });

    const handleShop = action(() => {
        menu.isOpen = !menu.isOpen;
        menu.shop = true;
        [menu.experiences, menu.galleries, menu.dogs, menu.contact] = false;
    });
    
    const handleContact = action(() => {
        menu.isOpen = !menu.isOpen;
        menu.contact = true;
        [menu.galleries, menu.dogs, menu.shop, menu.experiences] = false;
    });

    return (
        <Jacket isOpen={menu.isOpen}>
            <MenuList isOpen={menu.isOpen}>
                <Item isOpen={menu.isOpen} activePage={menu.galleries} onClick={handleGalleries}>
                    <Link to="/galleries">Galleries <span>{page.info.c}</span></Link>
                </Item>
                <Item isOpen={menu.isOpen} activePage={menu.experiences} onClick={handleExperiences}>
                    <Link to="/experiences">Experiences <span>{exp.info.c}</span></Link>
                </Item>
                <Item isOpen={menu.isOpen} activePage={menu.dogs} onClick={handleDogs}>
                    <Link to="/dog-photography">Dog Photoshoots <span></span></Link>
                </Item>
                <Item isOpen={menu.isOpen} activePage={menu.shop} onClick={handleShop}>
                    <Link to="/shop">Shop <span></span></Link>
                </Item>
                <Item isOpen={menu.isOpen} activePage={menu.contact} onClick={handleContact}>
                    <Link to="/contact">Contact</Link>
                </Item>
            </MenuList>
        </Jacket>
    )
}

export default observer(MobileMenu);
