// Imports
// ------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section, Div, Span, H1 } from '@tackl';
import { pStyles } from '@tackl-type';
import Topo from '@images/ps-topo-bg.png';

// Styles
// ------
export const Jacket = styled(Section)`
    transition: all 1s ${props => props.theme.bezzy};
    opacity: ${props => props.isComplete ? `0` : `1`};
    pointer-events: none;

    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 1300;

    background: ${props => props.theme.bc3};
`;

const sharedParts = css`
    ${props => props.theme.talign};
    width: 100%;
    height: 100%;

    transition: all 1s ${props => props.theme.bezzy};
    opacity: ${props => props.isVisible ? `1` : `0`};

    ${breakup.large`
        height: 100vh;
    `}
`;


// NOTE • Part One
export const PartOne = styled(Div)`
    ${sharedParts}
    transition-delay: 1.5s;
`;

export const Title = styled(H1)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    transform: translateY(-10rem);
    color: ${props => props.theme.bc1};

    div {
        transition: all 1s ${props => props.theme.bezzy};
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: top;
        display: block;
        width: 100%;

        &:last-child {
            margin-top: 10vh;

            ${breakup.medium` margin-top: 6rem; `}
            ${breakup.large` margin-top: 9rem; `}
        }
        
        span {
            transition: all 0.8s ${props => props.theme.bezzy};
            display: block;
            padding: 0 2.4rem;

            height: 100%;
            clip-path: inset(${(props) => props.isVisible ? `0% 0% 0% 0%` : `50% 0% 50% 0%`});
            width: 100%;
            overflow: hidden;
            text-align: center;

            font-size: 10vw;
            line-height: 1.1;
            
            ${breakup.medium` font-size: 6vw; `}
        }
    }
`;

export const David = styled(Div)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2.4rem;
    padding-top: 1.2rem;

    position: fixed;
    top: calc(50% + 6rem); left: 50%;
    transform: translate(-50%, -50%);

    ${breakup.large` top: calc(50% + 10rem); `}

    &:before {
        content: '';

        transition: all 1s ${props => props.theme.bezzy};
        position: absolute;
        top: 0; left: 50%;
        transform: translateX(-50%);

        display: block;
        width: ${props => props.isVisible ? `6rem` : `0rem`};
        height: 1px;
        background: ${props => props.theme.white};
        opacity: ${props => props.isVisible ? `1` : `0`};
    }

    span {
        transition: all 0.8s ${props => props.theme.bezzy};
        
        ${pStyles}
        letter-spacing: 2px;
        text-transform: uppercase;
        color: ${props => props.theme.white};

        display: block;
        height: 100%;
        max-height: ${props => props.isVisible ? `8rem` : `0rem`};
        opacity: ${props => props.isVisible ? `1` : `0`};
        width: max-content;
        overflow: hidden;
    }
`;


export const TitleJacket = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
`;

export const TitleText = styled(H1)`
    transition: all 1s ${props => props.theme.bezzy};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // height: 100%;
    width: 100%;
    overflow: hidden;
    clip-path: inset(${(props) => props.isVisible ? `0% 0% 0% 0%` : `50% 0% 50% 0%`});

    span {
        width: 100%;
        text-align: center;

        font-size: 10vw;
        line-height: 1.1;
        color: ${props => props.theme.bc1};

        ${breakup.medium` font-size: 6vw; `}
    }
`;

export const TitleSub = styled.div`
    position: relative;
    margin-top: 2.4rem;

    &:before {
        content: '';

        transition: all 1s ${props => props.theme.bezzy};
        position: absolute;
        top: -1.2rem; left: 50%;
        transform: translateX(-50%);

        display: block;
        width: ${props => props.isVisible ? `6rem` : `0rem`};
        height: 1px;
        background: ${props => props.theme.white};
        opacity: ${props => props.isVisible ? `1` : `0`};
    }

    span {
        transition: all 0.8s ${props => props.theme.bezzy};
        
        ${pStyles}
        letter-spacing: 2px;
        text-transform: uppercase;
        color: ${props => props.theme.white};

        display: block;
        height: 100%;
        max-height: ${props => props.isVisible ? `8rem` : `0rem`};
        opacity: ${props => props.isVisible ? `1` : `0`};
        width: max-content;
        overflow: hidden;
    }
`;


// NOTE • Part Two
export const PartTwo = styled(Div)`
    ${sharedParts}
    transition-delay: .5s;
`;

export const Box = styled(Div)((p) => css`
    position: fixed;
    top: 50%; left: 50%;
    z-index: 3;

    display: block;

    width: 80vw;
    height: 80vw;
    box-sizing: border-box;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2378674DFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

    transition: all 1.2s ${p.theme.bezzy};
    transform: translate(-50%, -50%) scale(${p.isVisible ? `0.98` : `0`});
    transform-origin: center center;
    transition-delay: .5s;

    ${breakup.medium`
        width: 50vw;
        height: 50vw;
    `}

    ${breakup.large`
        width: 34vw;
        height: 34vw;
    `}

    ${p.notVisible && (`
        transform: translate(-50%, -50%) scale(0);
    `)}
`);

export const Word = styled(Span)((p) => css`
    transition: all 1.2s ${p.theme.bezzy};
    display: block;
    overflow: hidden;
    z-index: 2;

    font-family: ${p.theme.fancy};
    font-size: 24vw;
    line-height: 100%;
    color: ${p.theme.black};

    ${breakup.large`
        font-size: 18vw;
    `}

    ${p.topLeft && css`
        position: absolute;
        top: 22%; left: 0;
        transform: translateY(-22%) scaleX(${p.isVisible ? `1` : `0`});
        transform-origin: left;
        transition-delay: .5s;

        ${breakup.medium`
            top: 32%;
            transform: translateY(-32%) scaleX(${p.isVisible ? `1` : `0`});
        `}

        ${p.notVisible && (`
            transform: translateY(-40%) scaleX(0);
        `)}
    `}

    ${p.bottomRight && css`
        position: absolute;
        bottom: 16%; right: 0;

        transform: translateY(-16%) scaleX(${p.isVisible ? `1` : `0`});
        transform-origin: right;
        transition-delay: .5s;

        ${breakup.medium`
            bottom: 20%;
            transform: translateY(-20%) scaleX(${p.isVisible ? `1` : `0`});
        `}
        
        ${breakup.large`
            bottom: 16%;
            transform: translateY(-16%) scaleX(${p.isVisible ? `1` : `0`});
        `}

        ${p.notVisible && (`
            transform: translateY(-40%) scaleX(0);
        `)}
    `}
`);

const draw = keyframes`
    to {
        stroke-dashoffset: 0;
    }
`;

export const Wild = styled(Div)((props) => css`
    ${props.theme.talign}
    z-index: 4;
    width: 60%;

    transition: clip-path 1.2s ${props.theme.bezzy};
    transition-delay: ${props.notVisible ? `0s` : `1s`};

    clip-path: inset(${props.notVisible ? `50% 0% 50% 0%` : `0% 0% 0% 0%`});

    svg {
        fill: none;
        stroke: ${props.theme.bc1};
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 2.4px;

        stroke-dasharray: 450;
        stroke-dashoffset: 450;
        
        animation: ${draw} 2.6s linear forwards;
        animation-delay: 1s;
        animation-play-state: ${props.isVisible ? `running` : `paused`};
    }
`);

export const Topographic = styled(Div)((p) => css`
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url(${Topo}) repeat;

    transition: all 1.2s ${p.theme.bezzy};
    transition-delay: .4s;
    transform-origin: center center;
    
    max-height: 0;

    ${p.isVisible && css`
        width: 70%;
        max-height: 18rem;
        ${breakup.medium` max-height: 40rem; `}
    `}
    
    ${p.notVisible && `max-height: 0;`}
`);